jQuery(document).ready(function(jQuery) {

	if ( jQuery('.fixar').length ) {
		jQuery('.fixar').wrap( "<div class='fixo'></div>" );
	}
	

});

/*var fixar = jQuery( ".fixar" );
var offset = fixar.offset();

jQuery(window).scroll(function() {
	var fixoHeight = jQuery( ".fixo" ).height();
	
    if (jQuery(this).scrollTop() >= offset.top) {
        jQuery('.fixo').addClass('on');
        jQuery('body').css('padding-top',fixoHeight);
    }
    else {
        jQuery('.fixo').removeClass('on');
        jQuery('body').css('padding-top','0');
    }
});
*/